.path-piece-bg-div {
    background: rgb(63,0,106);
    background: linear-gradient(153deg, rgba(63,0,106,1) 0%, rgba(203,29,253,1) 62%, rgba(228,33,137,1) 100%);
    border-radius: 15px;
    display: block;
    z-index: 5;
}

.path-piece-div {
    background-color: white;
    padding: 8px;
    margin: 6px;
    border-radius: 15px;
    z-index: 5;
}

.path-piece-container {
    position: absolute;

    animation: card-hover 4s ease-in-out infinite, card-rotation 3s ease-in-out infinite;
}

@keyframes card-hover {
    0%   {padding-top: 0%;}
    50%  {padding-top: 4%;}
    100% {padding-top: 0%;}
}

@keyframes card-rotation {
    0%   {transform: rotate(3deg);}
    50%  {transform: rotate(-3deg);}
    100% {transform: rotate(3deg);}
}

.round-gradient {
    background: rgb(75,4,94);
    background: radial-gradient(circle, rgba(75,4,94,0.6) 0%, rgba(6,6,26,0.6) 100%); 
}

.cloud {
    position: absolute;

    animation-name: fly-by;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes fly-by {
    0%   {opacity: 0%; left: 0%;}
    15%, 85%  {opacity: 40%;}
    100%   {opacity: 0%; left: 88%;}
}


/***** small width (phone portrait) *****/

@media only screen and (max-width: 480px) {

.path-piece-div h4 {
    font-size: 8px;
}
}


/***** medium width (phone landscape) *****/

@media only screen and (min-width: 480px) and (max-width: 960px) {

.path-piece-div h4 {
    font-size: 20px;
}
}