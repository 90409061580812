body {
  background-color: rgb(6, 6, 26);
}

.stars {
  opacity: 20%;
  z-index: -1;
  animation-name: bling;
  animation-iteration-count: infinite;
}

@keyframes bling {
  0%   {opacity: 20%;}
  45%  {opacity: 20%;}
  50%  {opacity: 60%;}
  100% {opacity: 20%;}
}

.component {
  animation-duration: 1s;
  animation-name: appear;
}

@keyframes appear {
  from {opacity: 0%;}
  to   {opacity: 100%;}
}

.cursorStar {
  position: absolute;
  animation-duration: 1s;
  animation-name: decend;
  animation-fill-mode: forwards;
}

@keyframes decend {
  from {opacity: 40%; margin-top: 0px;}
  to   {opacity: 0%; margin-top: 35px; transform: rotate( 75deg ) scale(0.5);}
}

.clickStar {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(322deg) brightness(112%) contrast(101%);
  z-index: 0;
  width: 40px;
  height: 40px;
  animation: blip 200ms ease-out;
}

@keyframes blip {
  from {opacity: 20%;}
  to   {opacity: 0%; transform: scale(2);}
}

.subtitle-extra {
  color: white;
  margin-bottom: 1.3rem !important;
  margin-top: 4rem !important;
}

.content-text-extra {
  line-height: 1.8 !important;
  color: white;
  margin-top: 10px !important;
}

.game-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.navbar {
  max-width: 500px;
  margin: 5rem auto 9rem auto;
}


/***** small width (phone portrait) *****/

@media only screen and (max-width: 480px) {
.title-text {
  font-size: 1.2rem !important;
}

.subtitle-extra {
  margin-bottom: 0rem !important;
  margin-top: 2rem !important;
}

.subtitle {
  font-size: 1.1rem !important;
}

.content-text {
  font-size: 0.6rem !important;
}

.navbar {
  max-height: 45px;
  max-width: 270px;
}

.navbar * {
  font-size: 14px !important;
  padding: 0 !important;
}

.game-logo {
  margin-top: 6rem;
}

}


/***** medium width (phone landscape) *****/

@media only screen and (min-width: 480px) and (max-width: 960px) {
  .title-text {
    font-size: 2rem !important;
  }
  
  .subtitle-extra {
    margin-bottom: 0.2rem !important;
    margin-top: 3rem !important;
  }
  
  .subtitle {
    font-size: 1.4rem !important;
  }
  
  .content-text {
    font-size: 1rem !important;
  }

  .navbar {
    max-width: 400px;
  }
  }

@media only screen and (orientation: landscape) {
  .game-logo {
    margin-top: 2rem;
    width: 70%;
  }
}