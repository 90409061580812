.devlog-div > .title-text {
    margin: 20px;
}


/***** small width (phone portrait) *****/

@media only screen and (max-width: 480px) {
    .devlog-div > .title-text {
        margin-left: 8px !important;
    }

    .devlog-div p {
        font-size: 10px !important;
    }
    
    }